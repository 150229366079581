var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"text-align":"left","min-width":"410px"}},[_c('div',{staticClass:"my-12 pb-12 pt-6",staticStyle:{"width":"75%","max-width":"843px","margin":"auto","font-family":"'Noto Sans JP', sans-serif","line-height":"45px","font-style":"normal","font-size":"20px"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('div',[_vm._m(0),_vm._m(1)]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndUp)?_c('div',[_vm._m(2),_vm._m(3)]):_vm._e(),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"margin-top":"30px","text-align":"center","min-width":"342px"}},[_c('span',{staticStyle:{"line-height":"212px","min-width":"342px","font-size":"57px","font-weight":"900"}},[_vm._v("よくある質問")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"text-align":"center","position":"absolute","top":"60px","left":"0","right":"0","width":"474px","height":"282px","margin":"auto"}},[_c('span',{staticStyle:{"font-family":"Helvetica","line-height":"300px","font-size":"237px","font-weight":"bold","opacity":"0.08"}},[_vm._v("FAQ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"text-align":"center","width":"100%"}},[_c('span',{staticStyle:{"line-height":"212px","min-width":"300px","font-size":"37px","font-weight":"900"}},[_vm._v("よくある質問")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"text-align":"center","position":"absolute","top":"30px","left":"0","right":"0","width":"100%","height":"282px","margin":"auto"}},[_c('span',{staticStyle:{"font-family":"Helvetica","line-height":"300px","font-size":"157px","font-weight":"bold","opacity":"0.08"}},[_vm._v("FAQ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"my-8"},[_c('div',{staticClass:"question"},[_c('span',[_vm._v(" Q：定期配送の「解約」や「休止」について教えてください。")])]),_c('div',[_c('span',[_vm._v(" A : いつでも「休止」や「解約」していただくことができます。お手続きは、『BimiStock』ホームページの「マイページ」にてお手続けます。「休止」及び「解約」に伴う違約金等はございません。 ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"my-8"},[_c('div',{staticClass:"question"},[_c('span',[_vm._v(" Q：保存食のセットはいつ頃届きますか？")])]),_c('div',[_c('span',[_vm._v(" A : ご入会日から1か月以内に配送開始となります。配送予定日は発送前に『BimiStock』ホームページの「マイページ」にてご確認いただけます。 ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"my-8"},[_c('div',{staticClass:"question"},[_c('span',[_vm._v("Q：賞味期限はどのようになっていますか？ ")])]),_c('div',[_c('span',[_vm._v(" A : 商品によってメーカーが設定している賞味期間に差がありますが、おおむね、お客様のお手元に到着後、約5か月から1年ほどとなります。各商品のパッケージに記載されている期限日をご確認の上、3か月の備蓄期間が終了した後は、できるだけ早く食していただくことをお勧めいたします。 ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"my-8"},[_c('div',{staticClass:"question"},[_c('span',[_vm._v("Q：送料はいくらですか？ ")])]),_c('div',[_c('span',[_vm._v(" A : お届けの地域と配送個数により異なりますが、約550円～700円（1～4人分の場合）ほどです。詳しい配送料は『BimiStock』ホームページ内の「配送について」ページにてご確認いただけます。 ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"my-8"},[_c('div',{staticClass:"question"},[_c('span',[_vm._v("Q：支払い方法と請求の頻度を教えてください。 ")])]),_c('div',[_c('span',[_vm._v(" A : お支払いはクレジットカードのみとなります。クレジットカードの種類の変更や期限更新によるカード情報更新などは、『BimiStock』ホームページのマイページにて行っていただけます。ご請求の頻度は3か月毎の商品発送後のご請求とさせていただきます。 ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"my-8"},[_c('div',{staticClass:"question"},[_c('span',[_vm._v("Q：返品について教えてください。 ")])]),_c('div',[_c('span',[_vm._v(" A : 原則として返品はお受けできません。但し、万一商品に何らかの瑕疵がある場合には商品をお取替え致します。瑕疵がある場合には、まずは『BimiStock』ホームページ内の「お問い合わせ」フォームかメールにてご一報ください。その後、お手続き方法等について詳しくご説明させていただきます。 ")])])])
}]

export { render, staticRenderFns }